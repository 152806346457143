import { useCallback, useEffect, useState } from 'react'
import CopyRenderer from '../../components/copyRenderer'
import { DownloadInstruction } from '../aiModelCard'
import {
    Tab,
    TabContent,
    TabLinks,
    TabLinksWrapper,
    TabWrapper,
} from './solutionLayout'

interface InstallationTabsProps {
    instructions: DownloadInstruction[]
}

const InstallationTabs = ({ instructions }: InstallationTabsProps) => {
    const [activeTab, setActiveTab] = useState<string | null>(null)

    useEffect(() => {
        if (instructions.length) {
            const initialActiveTab = instructions[0].title
            setActiveTab(initialActiveTab)
        }
    }, [])

    const renderTabs = () => {
        const tabsTitles = instructions.map((instruction) => instruction.title)
        return tabsTitles.map((title) => (
            <Tab
                key={title}
                onClick={() => setActiveTab(title)}
                className={activeTab === title ? 'activeTab' : ''}
            >
                <span>{title}</span>
            </Tab>
        ))
    }

    const renderContent = useCallback(() => {
        const activeInstruction = instructions.find(
            (instruction) => instruction.title === activeTab
        )
        const activeInstructionText =
            activeInstruction?.instruction ?? 'No instruction found'
        return <CopyRenderer text={activeInstructionText} />
    }, [activeTab])

    return (
        <TabWrapper>
            <TabLinksWrapper>
                <TabLinks>{renderTabs()}</TabLinks>
            </TabLinksWrapper>
            <TabContent>{renderContent()}</TabContent>
        </TabWrapper>
    )
}

export default InstallationTabs
