import { Icon } from '@iconify/react'
import {
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    Typography,
} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import styled from 'styled-components'
import CopyRenderer from '../../components/copyRenderer'
import theme from '../../containers/dashboardContainer/theme'
import { IAiModelCardProps } from '../aiModelCard'
import InstallationTabs from './installationTabs'

const PlaceholderCopy = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    h3 {
        font-weight: 300;
    }
`

const dialogHeaderBoxSx = {
    paddingBlock: 1.5,
    paddingLeft: 3,
    paddingRight: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}

const isJSON = (value: string) => {
    try {
        JSON.parse(value)
    } catch (e) {
        console.error(e)
        return false
    }
    return true
}

interface InstallationDialogProps {
    show: boolean
    model: IAiModelCardProps
    onClose: () => void
}

const InstallationDialog = ({
    show,
    model,
    onClose,
}: InstallationDialogProps) => {
    const renderContent = () => {
        if (!model.downloadDescription)
            return (
                <PlaceholderCopy>
                    <h3>Installation Details: Coming Soon</h3>
                </PlaceholderCopy>
            )

        if (isJSON(model.downloadDescription)) {
            const instructions = JSON.parse(model.downloadDescription)
            return <InstallationTabs instructions={instructions} />
        }

        return <CopyRenderer text={model.downloadDescription} />
    }

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={show}
                onClose={onClose}
                scroll="paper"
                maxWidth="lg"
                fullWidth
                sx={{
                    '& .MuiDialog-container': {
                        alignItems: 'flex-start',
                    },
                }}
            >
                <Box sx={dialogHeaderBoxSx}>
                    <Typography variant="h2" sx={{ margin: 0, fontSize: 20 }}>
                        Installation Instructions
                    </Typography>
                    <IconButton size="small" onClick={onClose}>
                        <Icon icon="mdi:close" />
                    </IconButton>
                </Box>
                <DialogContent dividers>
                    <DialogContentText>{renderContent()}</DialogContentText>
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    )
}

export default InstallationDialog
