/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/

import CardLinks from '../layouts/cardLinks'
import ContainerWrapper from '../layouts/containerWrapper'

const DiscoverContainer = () => {
    let isEnterpriseRagActive = false

    if (window.location.pathname.indexOf('chat') > -1)
        isEnterpriseRagActive = true
    else isEnterpriseRagActive = false

    const bodyContent = (isUserLoggedIn: boolean) => {
        return (
            <CardLinks
                title={!isEnterpriseRagActive ? 'All results' : 'Chat'}
                isUserLoggedIn={isUserLoggedIn}
            />
        )
    }

    return (
        <ContainerWrapper>
            {(isUserLoggedIn) => bodyContent(isUserLoggedIn)}
        </ContainerWrapper>
    )
}

export default DiscoverContainer
